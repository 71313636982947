import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import NavbarInfo from "./components/NavbarInfo";
import Main from "./components/Main";
import Footer from "./components/Footer";
import { useTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import { Routes, Route } from "react-router-dom";
import Success from "./components/Success";
import "./App.css";

function App() {
  const { t } = useTranslation();
  // const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }, []);
  return (
    <>
      {/* {loading ? (
        <div className="App">
          <PulseLoader color={"#327a57"} loading={loading} size={30} />
        </div>
      ) : ( */}
        <>
          <Header>
            <Navbar></Navbar>
            <NavbarInfo heading={t("navbar_info")}></NavbarInfo>
          </Header>
          <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/success" element={<Success />} />
          </Routes>
          <Footer></Footer>
        </>
      {/* )} */}
    </>
  );
}

export default App;
