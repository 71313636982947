import React from "react";
import { Col, Row } from "react-bootstrap";

function StepsForms({ text1, children }) {
  return (
    <Col sm={12} md={4} lg={4} xl={4}>
      <h3 className="title">{text1}</h3>
      <Row className="mt-4">
        <Col md={12}>{children}</Col>
      </Row>
    </Col>
  );
}

export default StepsForms;
