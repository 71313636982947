import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";

function InfoBar({ heading }) {
  return (
    <div className="bg-primary-custom">
      <Container>
        <Row>
          <Col md={12}>
            <p className="mb-0 text-white">
              <img
                src="../images/icons/invoice.png"
                alt="icon-invoice-white"
                title="icon-invoice-white"
              />
              {heading}
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default InfoBar;
