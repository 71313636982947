import React from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function DataInvoice({ spinerData, classText, children, userData }) {
  const { t } = useTranslation();
  return (
    <div className={classText}>
      <Row>
        <Col md={12}>
          <h3 className="title">{t("invoice_list")}</h3>
          <>
            <ListGroup className="mt-4">
              <ListGroup.Item className="border-bottom-0">
                {t("first_name")}
                <span className="float-end fw-bold">
                  {userData?.first_name}
                </span>
              </ListGroup.Item>
              <ListGroup.Item className="border-bottom-0">
                {t("last_name")}
                <span className="float-end fw-bold">{userData?.last_name}</span>
              </ListGroup.Item>
              <ListGroup.Item className="border-bottom-0">
                {t("customer_number")}
                <span className="float-end fw-bold">
                  {userData?.customer_number}
                </span>
              </ListGroup.Item>
              <ListGroup.Item className="border-bottom-0">
                {t("invoice_number")}
                <span className="float-end fw-bold">
                  {userData?.invoice_number}
                </span>
              </ListGroup.Item>
              <ListGroup.Item>
                {t("amount")}
                <span className="float-end fw-bold">
                  {userData?.amount} {t("valuta")}
                </span>
              </ListGroup.Item>
            </ListGroup>
          </>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <ListGroup className="mt-4">
            <ListGroup.Item className="fs-4 text-white bg-primary-red">
              {t("total")}:
              <span className="float-end">
                {userData?.amount || 0} {t("valuta")}
              </span>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      {children}
    </div>
  );
}

export default DataInvoice;
