import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';

import Arrow from "./Arrow";
import Alert from './Alert'
import StepsText from "./StepsText";
import { Container, Row } from "react-bootstrap";
import "./style.css";

const Success = () => {
    const location = useLocation()
    const navigation = useNavigate()
    const { t } = useTranslation();

    useEffect(() => {
      (!location?.state?.email || !location?.state?.invoice || !location?.state?.id) && navigation({pathname: '/', search: '?back=byforce'})
      return () => navigation({pathname: '/', search: '?back=byuser'})
  }, [location])

    useEffect(() => {
    (location?.state?.email || location?.state?.invoice || location?.state?.id) && emailjs.send('service_402ivnw', 'template_5wktf3g', {message: `Успешно платена фактура ${location?.state?.invoice} со единствен идентфикационен број ${location?.state?.id}.`,from_email: location?.state?.email,invoice_number: location?.state?.invoice}, 'HoW-t-yK3iFXKx3x0')
      .then((result) => {
          console.log(result);
      }, (error) => {
          console.log(error);
      })
    }, [])

    return (
    <div className="main">
      <Container>
        <Row className="d-none d-md-flex">
        <Arrow text={t("data_invoice")} isActive={true}></Arrow>
          <Arrow text={t("payment_status")} isActive={true}></Arrow>
        </Row>
          <>
            <Row className="body-step">
              <StepsText
                classText={"col-sm-12 col-md-4 col-lg-4 col-xl-4"}
                text1={t("step_3")}
              ></StepsText>
              <StepsText
                classText={"col-sm-12 col-md-8 col-lg-8 col-xl-8"}
                text1={t("status_payment")}
              >
               {location?.state?.email && location?.state?.invoice && <Alert
                  type="success"
                  classText={"alert alert-success mt-4 text-center"}
                  text1={t("payment_successful")}
                  text2={t("transaction_success", { email_user: location?.state?.email, invoice_number: location?.state?.invoice, invoice_id: location?.state?.id })}
                ></Alert>}
              </StepsText>
            </Row>
          </>
      </Container>
    </div>
    )
}

export default Success