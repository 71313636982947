import React from "react";
import { Alert, Button, Form, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InvoiceJsonData from "../axios/InvoiceJsonData";

function InvoiceForm({
  onSetsetSpinerData,
  onSetUserData
}) {
  const { t } = useTranslation();

  const cheme = yup.object().shape({
    first_name:  yup.string().required(t("required")),
    last_name:  yup.string().required(t("required")),
    customer_number: yup
      .number(t("not_valid_number"))
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : value;
      })
      .typeError(t("not_valid_number"))
      .positive(t("positive_number"))
      .integer(t("intiger_number"))
      .required(t("required")),
    invoice_number: yup
      .number(t("not_valid_number"))
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : value;
      })
      .typeError(t("not_valid_number"))
      .positive(t("positive_number"))
      .integer(t("intiger_number"))
      .required(t("required")),
      amount: yup
      .number(t("not_valid_number"))
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : value;
      })
      .typeError(t("not_valid_number"))
      .positive(t("positive_number"))
      .integer(t("intiger_number"))
      .required(t("required")),
      email: yup.string().email(t('correct_email')).required(t("required"))
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(cheme),
  });

  const onSubmit = async (dataInput) => {
    const id = dataInput.invoice_number +  new Date().getFullYear().toString().slice(-2)
    onSetsetSpinerData(true);
    onSetUserData({id, ...dataInput})
    //dataInputs have customer_number and invoice_number for send server and get data
      reset()
  };

  return (
    <>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="form-floating mb-3">
        <Form.Control
          type="text"
          className="form-control"
          id="first_name"
          name="first_name"
          placeholder={t("first_name")}
          {...register("first_name")}
        ></Form.Control>
        <Form.Label>{t("first_name")}</Form.Label>
        <span className="text-danger">{errors.first_name?.message}</span>
      </Form.Group>

      <Form.Group className="form-floating mb-3">
        <Form.Control
          type="text"
          className="form-control"
          id="last_name"
          name="last_name"
          placeholder={t("last_name")}
          {...register("last_name")}
        ></Form.Control>
        <Form.Label>{t("last_name")}</Form.Label>
        <span className="text-danger">{errors.last_name?.message}</span>
      </Form.Group>
      <Form.Group className="form-floating mb-3">
        <Form.Control
          type="text"
          className="form-control"
          id="customer_number"
          name="customer_number"
          placeholder={t("customer_number")}
          {...register("customer_number")}
        ></Form.Control>
        <Form.Label>{t("customer_number")}</Form.Label>
        <span className="text-danger">{errors.customer_number?.message}</span>
      </Form.Group>
      <Form.Group className="form-floating mb-3">
        <Form.Control
          type="text"
          className="form-control"
          id="invoice_number"
          name="invoice_number"
          placeholder={t("invoice_number")}
          {...register("invoice_number")}
        ></Form.Control>
        <Form.Label>{t("invoice_number")}</Form.Label>
        <span className="text-danger">{errors.invoice_number?.message}</span>
      </Form.Group>
      <Form.Group className="form-floating mb-3">
        <Form.Control
          type="text"
          className="form-control"
          id="amount"
          name="amount"
          placeholder={t("amount")}
          {...register("amount")}
        ></Form.Control>
        <Form.Label>{t("amount")}</Form.Label>
        <span className="text-danger">{errors.amount?.message}</span>
      </Form.Group>
      <Form.Group className="form-floating mb-3">
        <Form.Control
          type="text"
          className="form-control"
          id="email"
          name="email"
          placeholder={t("email_address")}
          {...register("email")}
        ></Form.Control>
        <Form.Label>{t("email_address")}</Form.Label>
        <span className="text-danger">{errors.email?.message}</span>
      </Form.Group>
      <div className="d-grid gap-2 d-md-block">
        <Button className="btn btn-primary float-end" type="submit">
          {t("confirm")}
        </Button>
      </div>
    </Form></>
  );
}

export default InvoiceForm;