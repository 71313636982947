import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import Arrow from "./Arrow";
import StepsText from "./StepsText";
import StepsForms from "./StepsForm";
import DataInvoice from "./DataInvoice";
import InvoiceForm from "./InvoiceForm";
import { useTranslation } from "react-i18next";
import BtnPayBank from "./BtnPayBank";
import { Container, Row } from "react-bootstrap";
import "./style.css";

function Main() {
  const [steps, setSteps] = useState("step1");
  const [userData, setUserData] = useState(null)

  const serchQuery = useQuery()
  const navigation = useNavigate()

  useEffect(() => {
    serchQuery.get('email') && serchQuery.get('invoice') && navigation('/success', {state: {
      email: serchQuery.get('email'),
      invoice: serchQuery.get('invoice'),
      id: serchQuery.get('id')
    }})
  }, [])


  const [spinerData, setSpinerData] = useState(false);
  const { t } = useTranslation();
  /* steps 3 if success payment show email for invoice payment */
  let email_user = "admenn94@gmail.com";

  return (
    <div className="main">
      <Container>
        <Row className="d-none d-md-flex">
          <Arrow text={t("data_invoice")} isActive={steps === 'step1' || steps === 'step2' ? true : false}></Arrow>
          {/* <Arrow text={t("email_confirmation")} isActive={steps === 'step2' ? true : false}></Arrow> */}
          <Arrow text={t("payment_status")} isActive={steps === 'step3' ? true : false}></Arrow>
        </Row>
        {/* Steps 1 */}
        {steps === "step1" && (
          <>
            <Row className="body-step">
              <StepsText
                classText={"col-sm-12 col-md-4 col-lg-4 col-xl-4"}
                text1={t("step_1")}
              ></StepsText>
              <StepsText
                classText={"col-sm-12 col-md-8 col-lg-8 col-xl-8"}
                text1={t("data_invoice")}
                text2={t("s1_text_1")}
              ></StepsText>
            </Row>
            <Row className="body-step">
              <StepsForms
                text1={t("s1_text_2")}
              >
                <InvoiceForm
                  onSetsetSpinerData={setSpinerData}
                  onSetUserData={setUserData}
                ></InvoiceForm>
              </StepsForms>
              <DataInvoice
                spinerData={spinerData}
                classText={"col-sm-12 col-md-8 col-lg-8 col-xl-8"}
                steps={steps}
                userData={userData}
              >
                {userData && <BtnPayBank onBack={setSteps} dataServer={userData} userData={userData}></BtnPayBank>}
              </DataInvoice>
            </Row>
          </>
        )}
        {/* Steps 3 */}
        {/* {steps === "step3" && (
          <>
            <Row className="body-step">
              <StepsText
                classText={"col-sm-12 col-md-4 col-lg-4 col-xl-4"}
                text1={t("step_3")}
              ></StepsText>
              <StepsText
                classText={"col-sm-12 col-md-8 col-lg-8 col-xl-8"}
                text1={t("status_payment")}
              >
                <Alert
                  type="danger"
                  classText={"alert alert-danger mt-4 text-center"}
                  text1={t("payment_unsuccessful")}
                  text2={t("transaction_terminated")}
                ></Alert>
                <Alert
                  type="success"
                  classText={"alert alert-success mt-4 text-center"}
                  text1={t("payment_successful")}
                  text2={t("transaction_success", { email_user })}
                ></Alert>
              </StepsText>
            </Row>
          </>
        )} */}
      </Container>
    </div>
  );
}

export default Main;
