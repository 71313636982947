import React from "react";

function StepsText({ classText, text1, text2, children }) {
  return (
    <div className={classText}>
      <h3 className="title">{text1}</h3>
      <p>{text2}</p>
      {children}
    </div>
  );
}

export default StepsText;
