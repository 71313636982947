import React from "react";

function Alert({ classText, text1, text2 }) {
  return (
    <div className={classText} role="alert">
      <h3>{text1}</h3>
      <p>{text2}</p>
    </div>
  );
}

export default Alert;
