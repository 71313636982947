import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";

function Footer() {
  const { t } = useTranslation();
  const today = new Date();

  return (
    <footer className="footer mt-auto py-3 bg-light">
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <p className="text-muted mt-1 mb-0">
              Copyright © {today.getFullYear()} {t("app_title")}.  All Rights
              Reserved.
            </p>
          </Col>
          <Col sm={12} md={6} className="text-end">
            <img src="../images/icons/visa.png" alt="diners" />
            <img src="../images/icons/verified_by_visa.png" alt="diners" />
            <img src="../images/icons/master_card.png" alt="diners" />
            <img src="../images/icons/master_secure.png" alt="diners" />
            <img src="../images/icons/diners.png" alt="diners" />
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
