import React from "react";
import { Col } from "react-bootstrap";

function Arrow({ text, isActive }) {
  return (
    <Col md={4}>
      <div className={isActive ? 'step_active' : 'step_none'}>
        <p className="title-active ps-3">{text}</p>
      </div>
    </Col>
  );
}

export default Arrow;
