import React from "react";
import DropDownLanguage from "./DropDownLanguage";
import { useTranslation } from "react-i18next";

function Menu() {
  const { t } = useTranslation();
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
      <div className="container-md">
        <a className="navbar-brand" href="/">
          <img
            src="../images/logo-1.png"
            alt={t("alt_title_logo")}
            title={t("alt_title_logo")}
            className="img-fluid img-responsive"
            width="190"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item dropdown">
              <DropDownLanguage></DropDownLanguage>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
