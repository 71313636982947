import React from "react";
import { Button, Col } from "react-bootstrap";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import "./style.css";
import { sprintf } from "sprintf-js";
import md5 from "blueimp-md5";

function BtnPayBank({ dataServer }) {
  let todayNow = new Date().getFullYear()
  const { t } = useTranslation();
  let setLanguage;
  const currentLanguageCode = Cookies.get("i18next") || "mk";
  if (currentLanguageCode === "mk") {
    setLanguage = "mk-MK";
  } else if (currentLanguageCode === "en") {
    setLanguage = "en-US";
  } else {
    setLanguage = "mk-MK";
    // setLanguage = "sq-AL";
  }

  let url = "https://www.cpay.com.mk/client/Page/default.aspx?xml_id=/" + setLanguage + "/.loginToPay/.simple/";

  // CheckSum & CheckSumHeader
  let AmountToPay = (
    dataServer.amount * 100
  ).toString(); /* cena shto treba da se plati pomnozena so 100*/
  let PayToMerchant = "1000001715"; // ova ne treba da se menuva, dadeno od CaSys
  let MerchantName = "JP KOMUNALNO STRUGA"; // ova ne treba da se menuva, dadeno od CaSys
  let AmountCurrency = "MKD"; // valuta vo koja se vrshi plakjanjeto
  let Details1 =
  `${t("invoice")} ${dataServer.invoice_number.toString()}, ${dataServer.first_name} ${dataServer.last_name}` /* komentar za uplata */
  let Details2 =
    dataServer.id.toString(); /* unikatni redni broevi na uplata na faktura so koi podocna ke vrshite detekcija dali e plateno ili ne */
  let PaymentOKURL = `http://pay.komunalnostruga.mk?email=${dataServer?.email}&invoice=${dataServer?.invoice_number}&id=${dataServer?.id}`; // strana kade formata kje prenasocuva koga plakjanjeto e uspeshno
  let PaymentFailURL = "http://pay.komunalnostruga.mk/fail.html"; // strana kade formata kje prenasocuva koga plakjanjeto e neuspeshno
  let FirstName = dataServer.first_name.toString();
  let LastName = dataServer.last_name.toString();
  let OriginalAmount = "";
  let Address = "";
  let Country = "";
  let City = "";
  let Zip = "";
  let Telephone = "";
  let Email = "";
  let OriginalCurrency = "";
  let md5password = "jtCZvVDrTR5YvN84U5mLBjKuAfLHrcCQ";

  // step 2 - Vo ovie polinja se generira kolku karakteri sodrzi edno pole so zerofilled na 3 brojki, primer "Zdravo" sodrzi 6 bukvi i imate izgenerirano 006, ova kje vi bide potrebno podocna za generiranje na CheckSum i CheckSumHeader
  let AmountToPay2 = sprintf("%03d", parseInt(AmountToPay.length));
  let PayToMerchant2 = sprintf("%03d", parseInt(PayToMerchant.length));
  let MerchantName2 = sprintf("%03d", parseInt(MerchantName.length));
  let AmountCurrency2 = sprintf("%03d", parseInt(AmountCurrency.length));
  let Details12 = sprintf("%03d", parseInt(Details1.length));
  let Details22 = sprintf("%03d", parseInt(Details2.length));
  let PaymentOKURL2 = sprintf("%03d", parseInt(PaymentOKURL.length));
  let PaymentFailURL2 = sprintf("%03d", parseInt(PaymentFailURL.length));
  let FirstName2 = sprintf("%03d", parseInt(FirstName.length));
  let LastName2 = sprintf("%03d", parseInt(LastName.length));
  let OriginalAmount2 = sprintf("%03d", parseInt(OriginalAmount.length));
  let Address2 = sprintf("%03d", parseInt(Address.length));
  let Country2 = sprintf("%03d", parseInt(Country.length));
  let City2 = sprintf("%03d", parseInt(City.length));
  let Zip2 = sprintf("%03d", parseInt(Zip.length));
  let Telephone2 = sprintf("%03d", parseInt(Telephone.length));
  let Email2 = sprintf("%03d", parseInt(Email.length));
  let OriginalCurrency2 = sprintf("%03d", parseInt(OriginalCurrency.length));

  // step 3
  let myVar =
    AmountToPay2 +
    PayToMerchant2 +
    MerchantName2 +
    AmountCurrency2 +
    Details12 +
    Details22 +
    PaymentOKURL2 +
    PaymentFailURL2 +
    FirstName2 +
    LastName2 +
    Address2 +
    City2 +
    Zip2 +
    Country2 +
    Telephone2 +
    Email2 +
    OriginalAmount2 +
    OriginalCurrency2;
  let CheckSumHeader =
    "18AmountToPay,PayToMerchant,MerchantName,AmountCurrency,Details1,Details2,PaymentOKURL,PaymentFailURL,FirstName,LastName,Address,City,Zip,Country,Telephone,Email,OriginalAmount,OriginalCurrency," +
    myVar;
  let CheckSumHeader2 =
    CheckSumHeader +
    AmountToPay +
    PayToMerchant +
    MerchantName +
    AmountCurrency +
    Details1 +
    Details2 +
    PaymentOKURL +
    PaymentFailURL +
    FirstName +
    LastName +
    Address +
    City +
    Zip +
    Country +
    Telephone +
    Email +
    OriginalAmount +
    OriginalCurrency +
    md5password;
  let CheckSum = md5(CheckSumHeader2).toUpperCase();

  return (
    <>
      <Col md={12} className="mt-4">
        <div className="d-grid gap-2 d-md-block">
          <form
            action={url}
            method="post"
          >
            <input
              id="AmountToPay"
              name="AmountToPay"
              value={AmountToPay}
              type="hidden"
            />
            <input
              id="PayToMerchant"
              name="PayToMerchant"
              value={PayToMerchant}
              type="hidden"
            />
            <input
              id="MerchantName"
              name="MerchantName"
              value={MerchantName}
              type="hidden"
            />
            <input
              id="AmountCurrency"
              name="AmountCurrency"
              value={AmountCurrency}
              type="hidden"
            />
            <input
              id="Details1"
              name="Details1"
              value={Details1}
              type="hidden"
            />
            <input
              id="Details2"
              name="Details2"
              value={Details2}
              type="hidden"
            />
            <input
              id="PaymentOKURL"
              size="10"
              name="PaymentOKURL"
              value={PaymentOKURL}
              type="hidden"
            />
            <input
              id="PaymentFailURL"
              size="10"
              name="PaymentFailURL"
              value={PaymentFailURL}
              type="hidden"
            />
            <input
              id="CheckSumHeader"
              name="CheckSumHeader"
              value={CheckSumHeader}
              type="hidden"
            />

            <input
              id="CheckSum"
              name="CheckSum"
              value={CheckSum}
              type="hidden"
            />

            <input
              id="FirstName"
              size="10"
              name="FirstName"
              value={FirstName}
              type="hidden"
            />
            <input
              id="LastName"
              size="10"
              name="LastName"
              value={LastName}
              type="hidden"
            />
            <input
              id="Address"
              size="10"
              name="Address"
              value={Address}
              type="hidden"
            />
            <input id="City" size="10" name="City" value={City} type="hidden" />
            <input id="Zip" size="10" name="Zip" value={Zip} type="hidden" />
            <input
              id="Country"
              size="10"
              name="Country"
              value={Country}
              type="hidden"
            />
            <input
              id="Telephone"
              size="10"
              name="Telephone"
              value={Telephone}
              type="hidden"
            />
            <input
              id="Email"
              size="10"
              name="Email"
              value={Email}
              type="hidden"
            />
            <input
              id="OriginalAmount"
              name="OriginalAmount"
              value={OriginalAmount}
              type="hidden"
            />
            <input
              id="OriginalCurrency"
              name="OriginalCurrency"
              value={OriginalCurrency}
              type="hidden"
            />
            <Button
              className="btn btn-primary float-end"
              value="Pay"
              type="submit"
            >
              {t("pay")}
            </Button>
          </form>
        </div>
      </Col>
    </>
  );
}

export default BtnPayBank;
