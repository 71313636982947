import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
/* Language */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
/* For language pass in cookie / Browser languageDetector*/
import LanguageDetector from "i18next-browser-languagedetector";
/* For language json call */
import HttpApi from "i18next-http-backend";
import { BrowserRouter } from "react-router-dom";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs: ["mk", "sq", "en"],
    fallbackLng: "mk",
    detection: {
      // order and from where user language should be detected
      order: [
        "path",
        "cookie",
        "htmlTag",
        "localStorage",
        "sessionStorage",
        "subdomain",
      ],
      caches: ["cookie"],
    },
    backend: { loadPath: "/assets/locales/{{lng}}/translate.json" },
    react: { useSuspense: false },
  });

ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById("root"));
